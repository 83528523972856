@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.s-min-height {
  min-width: 80px;
  @media only screen and (max-width: 1030px) {
    min-width: auto;
  }
}
.remove-borde-background {
  background-color: transparent;
  box-shadow: none;
}
